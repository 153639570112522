<template>

  <div class="login">
    <h1>Xinsi管理平台</h1>
    <div class="form-group">
      <el-input v-model="post_account"  placeholder="account" style="width: 180px"  clearable/>
    </div>
    <div class="form-group">
      <el-input v-model="post_password" type="password" placeholder="password" style="width: 180px; margin-top: 10px; margin-bottom: 10px" clearable/>
    </div>
    <el-button color="#0080D0" :dark="isDark" @click="postOne">Login</el-button>
  </div>
</template>

<style>
@media (min-width: 1024px) {
  .about {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>

<script>
import http from "../http-common"
export default {
  data() {
    return {
      post_account: null,
      post_password: null,
      postResult: null,
    }
  },
  methods: {

    fortmatResponse(res) {
      return JSON.stringify(res, null, 2);
    },

    async postOne() {
      const postData = {
        username: this.post_account,
        password: this.post_password,
      };

      http.post("/user/login", postData).then(successResponse => {
        const result = successResponse.data;
        this.postResult = JSON.stringify(result);
        console.log("result.message: " , result['message']);
        if (result['status'] === 'SUCCESS') {
          localStorage.setItem('token', result['message']);
          this.$router.push('/home');
        }
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },
  }

}
</script>