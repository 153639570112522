import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import GoodsAddView from "../views/GoodsAddView.vue";
import UserAddView from "../views/UserAddView.vue";
import GoodsListView from "../views/GoodsListView.vue";
import UserListView from "../views/UserListView.vue";
import OnCarListView from "../views/OnCarListView.vue";
//import PaypalView from "../views/PaypalView";



const router = createRouter({
    history: createWebHistory(""),
    routes: [
        {
            path: "/",
            name: "login",
            component: LoginView,
        },
        {
            path: "/home",
            name: "home",
            component: HomeView,
        },
        {
            path: "/goodsAdd",
            name: "goodsAdd",
            component: GoodsAddView,
        },
        {
            path: "/userAdd",
            name: "userAdd",
            component: UserAddView,
        },
        {
            path: "/goodsList",
            name: "goodsList",
            component: GoodsListView,
        },
        {
            path: "/userList",
            name: "userList",
            component: UserListView,
        },
        {
            path: "/onCarList",
            name: "onCarList",
            component: OnCarListView,
        },
/*        {
            path: "/paypal",
            name: "paypal",
            component: PaypalView,
        },*/
    ],
});

router.beforeEach((to, from, next) => {
    console.log('router beforeEach ')
    if (to.path === '/') {
        next()
    } else {
        let token = localStorage.getItem('token');
        console.log("beforeEach token: ", token)
        if (token == null || token === '') {
            console.log('请先登录')
            next({name:'login'});
        } else {
            next();
        }
    }
})

export default router;