<template>
  <div class="form-group">
    商品:
    <el-select v-model="goodsIdSelect.id" placeholder="商品ID" @change="getGoodsIdSelect" style="width: 150px">
      <el-option v-for="item in goodsInfoList" :key="item.id" :label="`${item.id}. ${item.goodsType}`" :value="`${item.id}`">
      </el-option>
    </el-select>
    用户:
    <el-select v-model="userIdSelect.id" placeholder="用户ID" @change="getUserIdSelect" style="width: 180px">
      <el-option v-for="item in userInfoList" :key="item.id" :label="`${item.id}. ${item.contact === null ? 'contact_unknow' : item.contact } ${item.goodsCount}`" :value="`${item.id}`">
      </el-option>
    </el-select>
    <el-input v-model="post_pay_price" class="form-control" placeholder="PayPrice" clearable style="margin-left: 5px; margin-right: 5px  ;width: 80px"/>
    <el-select v-model="debitTypeSelect.type" placeholder="请选择付款周期" @change="getDebitTypeSelect" >
      <el-option v-for="item in debitTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type}`">
      </el-option>
    </el-select>
    <el-date-picker
        v-model="createTime"
        type="datetime"
        placeholder="Pick a Date"
        format="YYYY-MM-DD HH:mm:ss"
        style="margin-left: 5px; margin-right: 10px"
    />
    <el-button color="#0080D0" @click="onCar">上车</el-button>
  </div>
  <span> </span>
  <div style="margin-top: 10px; margin-bottom: 10px">
    <div class="form-group">
      <el-select v-model="goodsTypeSelect.type" placeholder="请选择商品类型" @change="getGoodsTypeSelect">
        <el-option v-for="item in goodsTypes" :key="item.id" :label="item.type" :value="`${item.id}|${item.type}`">
        </el-option>
      </el-select>
      <el-input v-model="post_search" class="form-control" placeholder="商品关键字查找" style="margin-left: 5px; margin-right: 5px; width: 180px" clearable/>
      <el-button color="#0080D0"  @click="searchGoodsInfoPage(1, 1)">按创建时间查找</el-button>
      <el-button color="#0080D0"  @click="searchGoodsInfoPage(1, 2)">按续费时间查找</el-button>
      <el-button color="#0080D0"  @click="searchGoodsInfoPage(1, 3)">按剩余个数查找</el-button>
    </div>
  </div>
  <span>  </span>
  <el-table :data="goodList" stripe style="width: 100%"
            :header-cell-style="{ background: '#0080D0', color : '#ebf1f0' }"
            :row-class-name="tableRowClassName"
            border fit highlight-current-row>

    <el-table-column v-for="column in columnList" :key="column.prop">
      <!-- 自定义表头 -->
      <template #header>
        <!-- 段落：show为true -->
        <p v-show="column.show" @dblclick="column.show = false">
          {{column.label}}
          <i class="el-icon-edit-outline" @click="column.show = false"></i>
        </p>
        <!-- 输入框：show为false -->
        <el-input
            size="mini"
            v-show="!column.show"
            v-model="column.label"
            @blur="column.show = true">
        </el-input>
      </template>

      <!-- 自定义表项/单元格内容 -->
      <template #default="scope">
        <!-- 双击文字或点击修改图标以更改"show"属性 -->
        <!-- scope.row为元数据，column.col为该列的'键' -->
        <p v-show="scope.row[column.prop].show && column.prop !=='debitType' &&  column.prop !=='goodsType' "  @dblclick="scope.row[column.prop].show = false">
          {{ (scope.row[column.prop].content === null || scope.row[column.prop].content === '' ) ? "  -" : scope.row[column.prop].content}}
          <i class="el-icon-edit-outline" @click="scope.row[column.prop].show = false"/>
        </p>
        <!-- 失去焦点时更改"show"属性，显示文本 -->
        <el-input
            type="textarea"
            :autosize="{minRows:2,maxRows:4}"
            v-show="!scope.row[column.prop].show && column.prop !=='nextDebitTime' &&  column.prop !=='debitType'"
            v-model="scope.row[column.prop].content"
            @blur="scope.row[column.prop].show=true"/>

        <el-select v-model="scope.row[column.prop].content" @focus="getAllDebitTypes()" placeholder="请选择" @change="getItemDebitTypeSelect"
                   v-if="column.prop ==='debitType'">
          <el-option v-for="item in debitTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type} | ${scope.$index}`">
          </el-option>
        </el-select>

        <el-select v-model="scope.row[column.prop].content" @focus="getAllGoodsTypes()" placeholder="请选择" @change="getItemGoodsTypeSelect"
                   v-if="column.prop ==='goodsType'">
          <el-option v-for="item in goodsTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type} | ${scope.$index}`">
          </el-option>
        </el-select>
<!--
        <el-select v-model="scope.row[column.prop].content" @focus="get()" placeholder="请选择" @change="getItemDebitTypeSelect"
                   v-if="column.prop ==='priceUnit'">
          <el-option v-for="item in debitTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type} | ${scope.$index}`">
          </el-option>
        </el-select>-->

        <el-date-picker
            v-model="scope.row[column.prop].content"
            v-if="!scope.row[column.prop].show && column.prop ==='nextDebitTime'"
            @blur="scope.row[column.prop].show=true"
            type="datetime"
            placeholder="Pick a Date"
            format="YYYY-MM-DD"
            style="margin-left: 5px; margin-right: 10px">
        </el-date-picker>

      </template>
    </el-table-column>


    <el-table-column v-if="columnList.length > 0"   :label="'操作'" :width="260">

      <template #default="scope">
        <el-button color="#0080D0" @click="update(scope.$index, scope.row)">修改</el-button>
        <el-button color="#0080D0" @click="renew(scope.$index, scope.row)">续费</el-button>
        <el-button color="#DC143C" @click="deleteItem(scope.$index, scope.row)">删除</el-button>

      </template>
    </el-table-column>

<!--    <el-table-column prop="id" label="ID" width="50"/>
    <el-table-column prop="goodsType" label="GoodsType" width="120"/>
    <el-table-column prop="accoutId" label="Accout" />
    <el-table-column prop="goodsPassword" label="Password"  />
    <el-table-column prop="debitType" label="DebitType"  width="100"/>
    <el-table-column prop="createTime" label="CreateTime" width="100"/>
    <el-table-column prop="price" label="Price" width="60"/>
    <el-table-column prop="priceUnit" label="PriceUnit" width="100"/>
    <el-table-column prop="amount" label="Amount" width="80"/>
    <el-table-column prop="payment" label="Payment" />
    <el-table-column prop="inviteLink" label="InviteLink" />-->
  </el-table>
  <span>  </span>
  <div class="paginationClass">
    <el-pagination
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1" :current-page="currentPage1"
        :page-sizes="pageSizes"
        :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total1">
    </el-pagination>
  </div>
  <span>  </span>
  <div class="form-group" style="margin-top: 20px" v-show="false">
    <el-input v-model="post_search_user_txt" class="form-control" placeholder="用户关键字查找" style="margin-left: 5px; margin-right: 5px; width: 180px" clearable />
    <el-button color="#0080D0"  @click="getUserInfo">查找</el-button>

  </div>
  <span> </span>
  <el-table :data="userInfoList" stripe style="width: 100%; margin-top: 10px"
            :header-cell-style="{ background: '#0080D0', color : '#ebf1f0' }"
            border fit highlight-current-row v-show="false">
    <el-table-column prop="id" label="ID" width="50"/>
    <el-table-column prop="tgId" label="TGId" width="150"/>
    <el-table-column prop="tgName" label="TgName" width="150" />
    <el-table-column prop="wechatId" label="WechatId" width="150"  />
    <el-table-column prop="wechatName" label="WechatName"  width="150"/>
    <el-table-column prop="saltyFishName" label="SaltyFishName" width="150"/>
    <el-table-column prop="phoneNumber" label="phoneNumber" width="200"/>
    <el-table-column prop="ihezu" label="ihezu" width="150"/>
    <el-table-column prop="contact" label="contact" width="130"/>
    <el-table-column prop="email" label="email" width="200"/>
  </el-table>
  <span> </span>

</template>

<script>
import http from "../http-common"
import dayjs from "dayjs";

export default {
  data() {

    return  {
      post_search_user_txt: null,
      post_search: null,
      post_pay_price: null,
      goodsInfoList : [
        {
          id: null,
          goodsTypeId : null,
          price: null,
          priceUnit: null,
          createTime: null,
          amount: null,
          debitTypeId: null,
          nextDebitTime: null,
          inviteLink: null,
          accoutId: null,
          goodsPassword: null,
          payment: null,
          goodsType: null,
          debitType: null,
        },

      ],
      goodList : [],
      createTime: null,
      total1: null,
      pageSize: null,
      pageSizes: [8, 12, 14],
      currentPage1 : null,
      userInfoList : [
        {
          id: null,
          tgId: null,
          tgName: null,
          wechatId: null,
          wechatName: null,
          saltyFishName: null,
          phoneNumber: null,
          ihezu: null,
          contact: null,
          email: null,
          goodsCount: null,
        },

      ],
      goodsIdSelect : {
        id : 0,
      },
      userIdSelect : {
        id : 0,
      },
      goodsTypeSelect : {
        id : 0,
        type : "",
      },
      debitTypeSelect : {
        id : 0,
        type : "",
      },
      order: null,
      goodsTypes: null,
      debitTypes: null,
      columnList: [
        { prop: "id", label: 'ID', show: true },
        { prop: "goodsType", label: 'GoodsType', show: true },
        { prop: "accoutId", label: 'Accout', show: true },
        { prop: "goodsPassword", label: 'Password', show: true },
        { prop: "debitType", label: 'DebitType', show: true },
        { prop: "nextDebitTime", label: 'NextDebitTime', show: true },
        { prop: "price", label: 'Price', show: true },
        { prop: "priceUnit", label: 'PriceUnit', show: true },
        { prop: "amount", label: 'Amount', show: true },
        { prop: "payment", label: 'Payment', show: true },
        { prop: "inviteLink", label: 'InviteLink', show: true },
      ],
    }
  },
  mounted() {
    this.createTime = this.timeDefault;
    console.log("GoodsListView mounted()")
  },
  created() {
    this.getGoodsInfoPage();
    this.getAllGoodsTypes();
    this.getAllDebitTypes();
  },
  computed: {
    // 默认时间
    timeDefault () {
      console.log("GoodsListView computed()")
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
          new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes();
      let ss =
          new Date().getSeconds() < 10
              ? "0" + new Date().getSeconds()
              : new Date().getSeconds();

      return yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
    }
  },
  methods: {
      async getGoodsInfoPage() {
        const requestData = {
          page: 1,
          pageSize: this.pageSize === null ? 8 : this.pageSize,
        }
        console.log('xxxxxxxxxxxxxxxxxxxx getGoodsInfoPage *************** requestData: ', requestData)
        http.post("/goods/getGoodsInfoPage", requestData, {
          //
        }).then(successResponse => {

          const result = successResponse.data;
          this.goodsInfoList = result.list;

          this.goodList = [];
          for (let i = 0; i < this.goodsInfoList.length; i++) {
            const item = {
              id : {content: null, show: true},
              goodsType : {content: null, show: true},
              accoutId : {content: null, show: true},
              goodsPassword : {content: null, show: true},
              debitType : {content: null, show: true},
              nextDebitTime : {content: null, show: true},
              price : {content: null, show: true},
              priceUnit : {content: null, show: true},
              amount : {content: null, show: true},
              payment : {content: null, show: true},
              inviteLink : {content: null, show: true},
            }

            item.id.content = this.goodsInfoList[i].id;
            item.goodsType.content = this.goodsInfoList[i].goodsType;
            item.accoutId.content = this.goodsInfoList[i].accoutId;
            item.goodsPassword.content = this.goodsInfoList[i].goodsPassword;
            item.debitType.content = this.goodsInfoList[i].debitType;
            item.nextDebitTime.content = dayjs(this.goodsInfoList[i].nextDebitTime).format("YYYY-MM-DD");
            item.price.content = this.goodsInfoList[i].price;
            item.priceUnit.content = this.goodsInfoList[i].priceUnit;
            item.amount.content = this.goodsInfoList[i].amount;
            item.payment.content = this.goodsInfoList[i].payment;
            item.inviteLink.content = this.goodsInfoList[i].inviteLink;

            this.goodList.push(item);
          }

          this.total1 = result.total;
          this.pageSize = result.pageSize;
          this.currentPage1 = result.pageNum;
        }).catch(failResponse => {
          failResponse.error
        })
      },
      getGoodsIdSelect(e) {
        this.goodsIdSelect.id = e;
      },
      getUserIdSelect(e) {
        this.userIdSelect.id = e;
      },
      getItemGoodsTypeSelect(e) {
        let itemGoodsTypeSelectId = null;
        let itemGoodsTypeSelect = null;
        let index = null;
        [itemGoodsTypeSelectId, itemGoodsTypeSelect, index] = e.split('|');
        this.goodsInfoList.at(index).goodsTypeId = itemGoodsTypeSelectId;
        console.log(' itemGoodsTypeSelect: ' + itemGoodsTypeSelect)
      },
      getGoodsTypeSelect(e) {
        //let [id , type] = e;
        [this.goodsTypeSelect.id, this.goodsTypeSelect.type] = e.split('|');
        console.log("select GoodsType id: " + this.goodsTypeSelect.id + ' type: ' + this.goodsTypeSelect.type)
      },
      getDebitTypeSelect(e) {
        [this.debitTypeSelect.id, this.debitTypeSelect.type] = e.split('|');
        //let [id, type] = e;
        console.log("select DebitType id: " + this.debitTypeSelect.id + ' type: ' + this.debitTypeSelect.type)
      },
      getItemDebitTypeSelect(e) {
        let itemDebitTypeSelectId = null;
        let itemDebitType = null;
        let index = null;
        [itemDebitTypeSelectId, itemDebitType, index] = e.split('|');
        console.log("index: " + index + ", itemDebitType: " + itemDebitType + ", itemDebitTypeSelectId: "
            +  itemDebitTypeSelectId + "this.goodsInfoList[index]: " + this.goodsInfoList[index])
        this.goodsInfoList.at(index).debitTypeId =  itemDebitTypeSelectId;

        console.log("select DebitType id: " + this.debitTypeSelect.id + ' type: ' + itemDebitType)
      },
      searchGoodsInfoPage(p, order) {
        if (order !== undefined && order !== null) {
          this.order = order;
        }
        console.log('this.goodsTypeSelect: ' + this.goodsTypeSelect.id)
        const requestData = {
          goodsTypeId : this.goodsTypeSelect.id === 0 ? null : this.goodsTypeSelect.id,
          searchTxt : this.post_search,
          page: p,
          pageSize: this.pageSize === null ? 8 : this.pageSize,
          order : this.order
        }
        http.post("/goods/getGoodsInfoPage", requestData, {
          //
        }).then(successResponse => {
          const result = successResponse.data;
          this.goodsInfoList = result.list;
          this.goodList = [];
          for (let i = 0; i < this.goodsInfoList.length; i++) {
            const item = {
              id : {content: null, show: true},
              goodsType : {content: null, show: true},
              accoutId : {content: null, show: true},
              goodsPassword : {content: null, show: true},
              debitType : {content: null, show: true},
              nextDebitTime : {content: null, show: true},
              price : {content: null, show: true},
              priceUnit : {content: null, show: true},
              amount : {content: null, show: true},
              payment : {content: null, show: true},
              inviteLink : {content: null, show: true},
            }

            item.id.content = this.goodsInfoList[i].id;
            item.goodsType.content = this.goodsInfoList[i].goodsType;
            item.accoutId.content = this.goodsInfoList[i].accoutId;
            item.goodsPassword.content = this.goodsInfoList[i].goodsPassword;
            item.debitType.content = this.goodsInfoList[i].debitType;
            item.nextDebitTime.content = dayjs(this.goodsInfoList[i].nextDebitTime).format("YYYY-MM-DD");
            item.price.content = this.goodsInfoList[i].price;
            item.priceUnit.content = this.goodsInfoList[i].priceUnit;
            item.amount.content = this.goodsInfoList[i].amount;
            item.payment.content = this.goodsInfoList[i].payment;
            item.inviteLink.content = this.goodsInfoList[i].inviteLink;

            this.goodList.push(item);
          }
          this.total1 = result.total;
          this.pageSize = result.pageSize;
          this.currentPage1 = result.pageNum;

          //this.goodsIdSelect.id = 0; //重置
        }).catch(failResponse => {
          console.log(failResponse.error)
        })

        this.getUserInfo();
      },
      onCar() {
        const postData = {
          goodsId : this.goodsIdSelect.id,
          userId : this.userIdSelect.id,
          payAmount : this.post_pay_price,
          debitTypeId : this.debitTypeSelect.id,
          createTime: dayjs(this.createTime).format("YYYY-MM-DD HH:mm:ss"),
        }
        console.log("postData: " + postData)
        http.post("/goods/getInCar", postData, {
          //
        }).then(successResponse => {
          const response = successResponse.data;
          if (response.status === 'SUCCESS') {
            alert("上车成功 条目数: " + response.data)
            this.searchGoodsInfoPage(1, 1);
          } else {
            alert("上车成功 失败 " + response.message)
          }
        }).catch(failedResponse => {
          console.log(failedResponse)
          alert("上车失败" + failedResponse)
        })

      },
    async getAllGoodsTypes() {
      http.get("/goods/getAllGoodsTypes", {
        headers: {
          "x-access-token": "token-value",
        },
      }).then(successResponse => {
        this.goodsTypes = successResponse.data.data;
        console.log("goodsTypes: " , this.goodsTypes);
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },
    async getAllDebitTypes() {
      http.get("/goods/getAllDebitTypes", {
        headers: {
          "x-access-token": "token-value",
        },
      }).then(successResponse => {
        this.debitTypes = successResponse.data.data;
        console.log("debitTypes: " , this.debitTypes);
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },
    async getUserInfo() {
      const postData = {
        searchTxt: this.post_search,
        goodsTypeId: this.goodsTypeSelect.id,
      };
        http.post("/goods/getUserInfo", postData, {
          //
        }).then(successResponse => {
          this.userInfoList = successResponse.data.data

          this.userIdSelect.id = 0;//重置
        }).catch(failedResponse => {
          console.log(failedResponse.error)
        })
    },
    handleSizeChange1: function(pageSize) { // 每页条数切换
      this.pageSize = pageSize
      this.handleCurrentChange1(this.currentPage1);
    },
    handleCurrentChange1: function(currentPage) {//页码切换
      this.currentPage1 = currentPage;
      this.searchGoodsInfoPage(currentPage);
      this.currentChangePage(this.bondsAllList,currentPage);

    },
    //分页方法（重点）
    currentChangePage(list,currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.tempList = [];
      for (; from < to; from++) {
        /*if (list[from]) {
          this.tempList.push(list[from]);
        }*/
      }
    },
    update(index, row) {
        const param = {
          id: null,
          goodsTypeId : null,
          price: null,
          //priceUnit: null,
          //createTime: null, 备用
          amount: null,
          debitTypeId: null,
          nextDebitTime: null,
          inviteLink: null,
          accoutId: null,
          goodsPassword: null,
          payment: null,
          //goodsType: null, 备用
          //debitType: null, 备用
        }

      param.id = row.id.content;
      param.price = row.price.content;
      param.amount = row.amount.content;
      param.nextDebitTime = dayjs(row.nextDebitTime.content).format("YYYY-MM-DD HH:mm:ss") ;
      param.inviteLink = row.inviteLink.content;
      param.accoutId = row.accoutId.content;
      param.goodsPassword = row.goodsPassword.content;
      param.payment = row.payment.content;
      param.debitTypeId = this.goodsInfoList[index].debitTypeId;
      param.goodsTypeId = this.goodsInfoList[index].goodsTypeId;

      console.log("param.debitTypeId: " + param.debitTypeId)
      http.post('goods/updateGoodsInfo', param, {

      }).then(successResult => {
        alert("修改成功: " + successResult.data.data)
      }).catch(error => {
        alert("修改失败" +  error)
      })
      console.log("index: ", index, row)
    },
    renew(index,row) {
        const param = {
          id : null,
          nextDebitTime: null,
          debitTypeId: null,
        }
        param.id = row.id.content;
        param.nextDebitTime = dayjs(row.nextDebitTime.content).format("YYYY-MM-DD HH:mm:ss");
        param.debitTypeId =  this.goodsInfoList[index].debitTypeId;
        http.post("goods/refillGoods", param, {

        }).then(successResult => {
          alert("续费成功: " + successResult.data.data)
        }).catch(error => {
          alert("续费失败" +  error)
        })
    },
    deleteItem(index, row) {
      const param = {
        id : null
      }
      param.id = row.id.content;
      http.post("goods/deleteGoodsInfoById", param, {

      }).then(successResult => {
        const result = successResult.data;
        console.log("deleteItem deleteGoodsInfoById success: " , result);
        if (result.status === "success") {
          alert("删除成功" + result.data)
        } else {
          alert(result.message)
        }
      }).catch(error => {
        alert("删除失败" +  error)
      })
    },
    tableRowClassName({ row, rowIndex }) {
      console.log("***************" + row.nextDebitTime.content + ", rowIndex: " + rowIndex + ", " + this.dateDiffer(row.nextDebitTime.content));
      //row.nextPayTime = '#3be6f8';
      /*       if (row.column.prop === 'nextPayTime' ){
               return 'color: #3be6f8';
             }*/

      if (this.dateDiffer(row.nextDebitTime.content) <= 15) {
        return 'info-row';
      }
    },
    dateDiffer(Date_end){
      //date1结束时间
      let date1 = new Date(Date_end);
      //date2当前时间
      let date2 = new Date();
      date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
      date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
      return diff / (24 * 60 * 60 * 1000);  //计算当前时间与结束时间之间相差天数
    }
  }
}
/**
 *        <el-date-picker
 *             v-model="scope.row[column.prop].content"
 *             v-if="!scope.row[column.prop].show && column.prop === 'email'"
 *             type="datetime"
 *             placeholder="Pick Date"
 *             format="YYYY-MM-DD"
 *             @blur="scope.row[column.prop].show=true"/>
 */
</script>

<style>
.el-table .info-row {
  color: #af3535;
}
</style>