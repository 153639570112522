import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus'
import router from "./router";
import axios from 'axios'
import VueAxios from 'vue-axios'

import "./assets/main.css";
import 'element-plus/dist/index.css'

const app = createApp(App);
app.use(VueAxios, axios);
app.use(ElementPlus);
app.use(router);

app.mount("#app");



