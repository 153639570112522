<template>
  <div class="greetings">
    <h1 class="green">用户订阅</h1>

<!--    <div class="form-group">
      <input type="text" class="form-control" ref="post_tg_id" placeholder="tgId" /> <input type="text" class="form-control" ref="post_tg_name" placeholder="tgName" />
    </div>
    <div class="form-group">
      <input type="text" class="form-control" ref="post_wechat_id" placeholder="wechatId" /> <input type="text" class="form-control" ref="post_wechat_name" placeholder="wechatName" />
    </div>
    <div class="form-group">
      <input type="text" class="form-control" ref="post_salty_fish_name" placeholder="saltyFishName" />
    </div>
    <div class="form-group">
      <input type="text" class="form-control" ref="post_phone_number" placeholder="phoneNumber" /><input type="text" class="form-control" ref="post_email" placeholder="email" />
    </div>-->
<!--    <div class="form-group">
      <input type="text" class="form-control" ref="post_taobao" placeholder="taobao" />
      <input type="text" class="form-control" ref="post_ihezu" placeholder="iHezu" />
    </div>-->
    <div class="form-group">
      <span>Email</span> <el-input v-model="post_email" class="form-control" placeholder="Email" clearable style="margin-left: 25px; margin-right: 5px; margin-bottom :10px; width: 230px"/>
    </div>
<!--    <div class="form-group">
      <span>商品类型</span>
      <el-select v-model="goodsTypeSelect.type" placeholder="请选择" @change="getGoodsTypeSelect">
        <el-option v-for="item in goodsTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type}`">
        </el-option>
      </el-select>
    </div>-->

    <div class="app">
      <el-form ref="form" :model="form">
        <el-form-item label="商品类型">
          <el-select v-model="form.phoneBrand" placeholder="请选择" @change="changeSelect">
            <el-option
                v-for="(item,index) in brandOptions"
                :key="index"
                :label="item.type"
                :value="item.id"
            />
          </el-select>

        </el-form-item>
        <el-form-item label="商品帐号">
          <el-select v-model="form.phoneType" placeholder="请选择" @change="accountChangeSelect">
            <el-option
                v-for="(item,index) in typeOptions"
                :key="index"
                :label="item.account"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="付款周期">
          <el-select v-model="debitTypeSelect.type" placeholder="请选择" @change="getDebitTypeSelect">
            <el-option v-for="item in debitTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type}`">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div>
    <span>付款金额</span><el-input v-model="post_pay_price" class="form-control" placeholder="PayPrice" clearable style="margin-left: 5px; margin-right: 5px  ;width: 80px"/>
    </div>
    <el-checkbox-group v-model="roleIds">
      <el-checkbox v-for="item in list" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
    </el-checkbox-group>

    <div>
    <span>备注</span> <el-input v-model="post_contact" class="form-control" placeholder="Contact" clearable style="margin-left: 25px; margin-right: 5px  ;width: 180px"/>
    </div>

    <div class="block">
      时间选择
      <el-date-picker
          v-model="createTime"
          type="datetime"
          placeholder="Pick a Date"
          format="YYYY-MM-DD HH:mm:ss"
      />
    </div>


    <div class="add">
      <button class="btn btn-sm btn-primary" @click="addUserInfo">用户订阅</button>
    </div>

    <el-upload class="upload-demo" action='' drag :auto-upload="false" :on-change="uploadChange" :limit="1">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    </el-upload>
    <el-button size="small" @click="fileup">提交</el-button>
  </div>


</template>

<script>
import http from "../http-common"
import dayjs from "dayjs";
//import xlsx from 'xlsx';


export default {
  data() {

    return {
      currentFile: null,
      debitTypes: null,
      goodsTypes: null,
      post_contact: null,
      post_pay_price: null,
      post_email: null,
      createTime: null,
      roleIds: [],
      list: [ { id: 1, name: 'wechat'}, { id: 2, name: 'telegram'}, {id: 3, name: 'ihezu'}, {id: 4, name: 'saltyfish'}, {id: 5, name:'iMessage'}],
      debitTypeSelect : {
        id : 0,
        type : "",
      },
      goodsTypeSelect : {
        id : 0,
        type : "",
      },
      brandOptions: null,
      brandObj: {
        'OPPO': ['OPPO1', 'OPPO2', 'OPPO3'],
        '华为': ['华为1', '华为2', '华为3'],
        'VIVO': ['VIVO1', 'VIVO2', 'VIVO3']
      },
      typeOptions: [],
      form: {
        phoneBrand: '',
        phoneType: '请选择'
      }
    }
  },
  mounted() {
    this.createTime = this.timeDefault;
    console.log("GoodsAddView mounted()")
  },
  created() {
    this.getAllDebitTypes();
    this.getAllGoodsTypes();
    this.getAccountsByType();
  },
  methods: {
    accountChangeSelect() {

      this.typeOptions.forEach((item)=>{
        if (this.form.phoneType === item.id) {
          this.post_contact = item.account
        }
      })
    },
    changeSelect() {
      // 清空手机型号内容
      this.form.phoneType = '请选择'

      // 遍历手机品牌的下拉选项数组
      for (const k in this.brandOptions) {
        // 手机品牌内容 是否等于 手机品牌的下拉选择数组中的某一项
        console.log("dsada" + this.form.phoneBrand)
        if (this.form.phoneBrand === this.brandOptions[k].id) {
          console.log("dsada" + this.brandOptions[k].type)
          this.typeOptions = this.brandObj[this.brandOptions[k].type.toLowerCase()]
        }
      }
    },
    getAccountsByType() {
      http.post("/goods/getAccountsByAllType", {
        headers: {
          "x-access-token": "token-value",
        },
      }).then(successResponse => {

        var str = JSON.stringify(successResponse.data.data)
        str = str.replace(/amazonPrime/g, 'amazonprime')
        str = str.replace(/appleMusic/g, 'applemusic')
        str = str.replace(/appleOne/g, 'appleone')
        str = str.replace(/appleiCloud/g, 'appleicloud')
        str = str.replace(/cakePlus/g, 'cakeplus')
        str = str.replace(/disneyPlus/g, 'disneyplus')
        str = str.replace(/hboMax/g, 'hbomax')
        str = str.replace(/youtubePremium/g, 'youtubepremium')
        str = str.replace(/googleOne/g, 'googleone')
        this.brandObj = JSON.parse(str);
        console.log("brandObj: " , this.brandObj);
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },
    getDebitTypeSelect(e) {
      [this.debitTypeSelect.id, this.debitTypeSelect.type] = e.split('|');

      //let [id, type] = e;
      console.log("select DebitType id: " + this.debitTypeSelect.id + ' type: ' + this.debitTypeSelect.type)
    },
    getGoodsTypeSelect(e) {
      //let [id , type] = e;
      [this.goodsTypeSelect.id, this.goodsTypeSelect.type] = e.split('|');
      console.log("select GoodsType id: " + this.goodsTypeSelect.id + ' type: ' + this.goodsTypeSelect.type)
    },
    async getAllDebitTypes() {
      http.get("/goods/getAllDebitTypes", {
        headers: {
          "x-access-token": "token-value",
        },
      }).then(successResponse => {
        this.debitTypes = successResponse.data.data;
        console.log("debitTypes: " , this.debitTypes);
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },
    async getAllGoodsTypes() {
      http.get("/goods/getAllGoodsTypes", {
        headers: {
          "x-access-token": "token-value",
        },
      }).then(successResponse => {
        this.goodsTypes = successResponse.data.data;
        this.brandOptions = this.goodsTypes

       /* this.brandObj.amazonPrime = this.brandObj.amazonPrime.toLowerCase();
        this.brandObj.appleMusic = this.brandObj.appleMusic.toLowerCase();
        this.brandObj.appleOne = this.brandObj.appleOne.toLowerCase();
        this.brandObj.appleiCloud = this.brandObj.appleiCloud.toLowerCase();
        this.brandObj.cakePlus = this.brandObj.cakePlus.toLowerCase();
        this.brandObj.disneyPlus = this.brandObj.disneyPlus.toLowerCase();
        this.brandObj.hboMax = this.brandObj.hboMax.toLowerCase();
        this.brandObj.youtubePremium = this.brandObj.youtubePremium.toLowerCase();*/

        console.log("goodsTypes: " , this.brandOptions);
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },
    async addUserInfo() {
      const userInfo = {
        tgId: null,
        //tgName: this.$refs.post_tg_name.value,
        wechatId: null,
        //wechatName: this.$refs.post_wechat_name.value,
        saltyFishName: null,
        //phoneNumber: this.$refs.post_phone_number.value,
        //taobao: this.$refs.post_taobao.value,
        email: this.post_email,
        contact: this.post_contact,
        ihezu: null,
      };

      const onCar = {
        payAmount: this.post_pay_price,
        debitTypeId: this.debitTypeSelect.id,
        goodsId: this.form.phoneType,
        createTime: dayjs(this.createTime).format("YYYY-MM-DD HH:mm:ss"),
        notice: 1
      };

      console.log("roleIds: " + this.roleIds)
      this.roleIds.forEach((item) => {
        switch (item) {
          case 1:
            userInfo.wechatId = 'Y'
            break;
          case 2:
            userInfo.tgId = 'Y'
            break;
          case 3:
            userInfo.ihezu = 'Y'
            break;
          case 4:
            userInfo.saltyFishName = 'Y'
            break;
        }
      });

      const userSubscribe = {
        userInfo,
        onCar
      }
      console.log("postData: " + JSON.stringify(userSubscribe) + this.form.phoneType.id)
      http.post("/goods/addUserSubscribe", userSubscribe, {
        headers: {
          "x-access-token": "token-value",
        },
      }).then(successResponse => {
        console.log("success: " , successResponse.data);
        if (successResponse.data.status === 'SUCCESS') {
          alert("添加并订阅成功")
        } else {
          alert(successResponse.data.message)
        }
      }).catch(failResponse => {
        console.log(failResponse)
        alert("添加失败" + failResponse)
      });
    },
    uploadChange(file){
      //let dataBinary = await this.readFile(file.raw)
      //console.log("dataBinary:  " + dataBinary)

      this.currentFile = file;

/*      let workBook = xlsx.read(dataBinary, {type: 'binary', cellDates: true})
      let workSheet = workBook.Sheets[workBook.SheetNames[0]]
      const data = xlsx.utils.sheet_to_json(workSheet)
      console.log(data)*/
    },
    async fileup() {
      let formdata = new FormData();
      formdata.append("upload", this.currentFile.raw)
      http.post("/goods/userExcelUpload", formdata, {
        headers:{
          // 注意设置编码类型
          'Content-Type': 'multipart/form-data'
        }
      }).then(successResult => {
        if (successResult.data === 'success') {
          alert("excel添加成功")
        } else {
          alert("excel添加失败")
        }
      }).catch(error => {
        console.log(error)
      })
    },
  },

}
</script>