<script>
import http from "../http-common"
export default {
  data() {
    return {
      postResult: null,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {

      http.get("/goods/getAllDebitTypes", {
/*        headers: {
          "x-access-token": "token-value",
        },*/
      }).then(successResponse => {
        const result = successResponse.data;
        this.postResult = JSON.stringify(result);
        console.log("result: " , successResponse);
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },

  }

}
</script>

<template>
  <div class="greetings">
  </div>
</template>

<style scoped>
h1 {
  font-weight: 500;
  font-size: 2.6rem;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}
</style>