<template>
<!--  <div class="form-group">
    <input type="text" class="form-control" ref="post_on_car_id" placeholder="onCarId" />
    <input type="text" class="form-control" ref="post_pay_amount" placeholder="payAmount" />
    <el-select v-model="debitTypeSelect.type" placeholder="请选择" @change="getDebitTypeSelect">
      <el-option v-for="item in debitTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type}`">
      </el-option>
    </el-select>
    <button class="btn btn-sm btn-primary" @click="refillCar">续费</button>
    <span>********* </span>
    <button class="btn btn-sm btn-primary" @click="returnRefill">撤销续费</button>
    <span>********* </span>
    <button class="btn btn-sm btn-primary" @click="offCar">下车</button>
  </div>
  <span> </span>-->
  <div class="form-group">
    <el-input type="text" class="form-control" v-model="searchTxt" placeholder="searchTxt"  style="width: 150px"/>
    <el-input type="text" class="form-control" v-model="accountId" placeholder="accountId" style="width: 150px"/>
    <el-button color="#0080D0" @click="getOnCarListPage(1)">搜索</el-button>
    <el-button color="#0080D0" @click="getRecentRefillListPage(1)">查看最近续费</el-button>
  </div>
  <span> </span>

  <el-table :data="onCarList" stripe style="width: 100%; margin-top: 10px" :header-cell-style="{ background: '#0080D0', color : '#ebf1f0' }"
            :row-class-name="tableRowClassName"
            border fit highlight-current-row>
    <el-table-column v-for="column in columnList" :key="column.prop">
      <!-- 自定义表头 -->
      <template #header>
        <!-- 段落：show为true -->
        <p v-show="column.show" @dblclick="column.show = false">
          {{column.label}}
          <i class="el-icon-edit-outline" @click="column.show = false"></i>
        </p>
        <!-- 输入框：show为false -->
        <el-input
            size="mini"
            v-show="!column.show"
            v-model="column.label"
            @blur="column.show = true">
        </el-input>
      </template>

      <!-- 自定义表项/单元格内容 -->
      <template #default="scope">
        <!-- 双击文字或点击修改图标以更改"show"属性 -->
        <!-- scope.row为元数据，column.col为该列的'键' -->
        <p v-show="scope.row[column.prop].show && column.prop != 'debitType'" @dblclick="scope.row[column.prop].show = false">
          {{ (scope.row[column.prop].content === null || scope.row[column.prop].content === '' ) ? "  -" : scope.row[column.prop].content}}
          <i class="el-icon-edit-outline" @click="scope.row[column.prop].show = false"/>
        </p>
        <!-- 失去焦点时更改"show"属性，显示文本 -->
        <el-input
            type="textarea"
            :autosize="{minRows:2,maxRows:4}"
            v-show="!scope.row[column.prop].show && column.prop != 'debitType'"
            v-model="scope.row[column.prop].content"
            @blur="scope.row[column.prop].show=true"/>

        <el-select v-model="scope.row[column.prop].content" @focus="getAllDebitTypes()" placeholder="请选择" @change="getItemDebitTypeSelect"
                   v-if="column.prop ==='debitType'">
          <el-option v-for="item in debitTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type} | ${scope.$index}`">
          </el-option>
        </el-select>
      </template>

    </el-table-column>


    <el-table-column v-if="columnList.length > 0"   :label="'操作'" :width="375">

      <template #default="scope">
        <el-button color="#0080D0" @click="refillCar(scope.$index, scope.row)">续费</el-button>
        <el-button color="#0080D0" @click="returnRefill(scope.$index, scope.row)">撤销</el-button>
        <el-button color="#0080D0" @click="offCar(scope.$index, scope.row)">下车</el-button>
        <el-button color="#0080D0" @click="getBill(scope.$index)">账单</el-button>
        <el-button color="#DC143C" @click="rejectRefill(scope.$index)">拒绝</el-button>
      </template>
    </el-table-column>
  </el-table>
<!--  <el-table :data="onCarInfoList" stripe style="width: 100%"
            class="onCarInfoList"
            :header-cell-style="{ background: '#0080D0', color : '#ebf1f0' } "
            border fit highlight-current-row
            :row-class-name="tableRowClassName">
    <el-table-column prop="id" label="onCarId" width="100"/>
    <el-table-column prop="goodsIdType" label="GoodsType" width="120"/>
    <el-table-column prop="accoutId" label="Accout" width="180" />
    <el-table-column prop="payAmount" label="Pay" width="80" />
    <el-table-column prop="contact" label="contact" width="150"/>
    <el-table-column prop="debitType" label="DebitType"  width="100" />
    <el-table-column prop="nextPayTime" label="NextPayTime" width="150"/>

  </el-table>-->
  <span>  </span>
  <div class="paginationClass">
    <el-pagination
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1" :current-page="currentPage1"
        :page-sizes="[10]"
        :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total1">
    </el-pagination>
  </div>
</template>
<script>
import http from "@/http-common";

export default {
  data() {
    return {
      onCarInfoList: [{
        recentPaymentTime: null,
        id: null,
        goodsIdType: null,
        accoutId: null,
        payAmount: null,
        debitType: null,
        wechatId: null,
        wechatName: null,
        tgId: null,
        tgName: null,
        saltyFishName: null,
        email: null,
        contact: null,
        nextPayTime: null,
        ihezu: null,
        uuid: null,
      }, {}],
      onCarList: [],
      columnList: [
        { prop: "recentPaymentTime", label: 'recentPaymentTime', show: true },
        { prop: "goodsIdType", label: 'GoodsIdType', show: true },
        { prop: "accoutId", label: 'AccoutId', show: true },
        { prop: "payAmount", label: 'PayAmount', show: true },
        { prop: "contact", label: 'Contact', show: true },
        { prop: "debitType", label: 'DebitType', show: true },
        { prop: "nextPayTime", label: 'NextPayTime', show: true },
      ],
      debitTypeSelect : {
        id : 0,
        type : "",
      },
      debitTypes: null,
      createTime: null,
      total1: null,
      pageSize: null,
      currentPage1 : null,
      searchTxt: null,
      accountId: null,
    }
  },
  created() {
    this.getAllDebitTypes();
    this.getOnCarListPage(1);
  },
  methods: {
    getDebitTypeSelect(e) {
      [this.debitTypeSelect.id, this.debitTypeSelect.type] = e.split('|');
      //let [id, type] = e;
      console.log("select DebitType id: " + this.debitTypeSelect.id + ' type: ' + this.debitTypeSelect.type)
    },
    async getAllDebitTypes() {
      http.get("/goods/getAllDebitTypes").then(successResponse => {
        this.debitTypes = successResponse.data.data;
        console.log("debitTypes: " , this.debitTypes);
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },
    async refillCar(index, row) {
      const refillParam = {
        carId: this.onCarInfoList[index].id,
        debitTypeId: this.onCarInfoList[index].debitTypeId,
        payAmount: row.payAmount.content,
      }
      http.post("/goods/refillCar", refillParam).then(successResponse => {
       const result = successResponse.data;
        if (result.status === 'SUCCESS') {
          alert("续费成功" + result.data)
        } else {
          alert("续费失败" + result.message)
        }
      }).catch(error => {
        alert("续费失败 exception: " + error)
      })
    },
    returnRefill(index, row) {
      const refillParam = {
        carId: this.onCarInfoList[index].id,
        debitTypeId: this.onCarInfoList[index].debitTypeId,
        payAmount: row.payAmount.content,
      }
      http.post("/goods/returnRefill", refillParam).then(successResponse => {
        const result = successResponse.data;
        if (result.status === 'SUCCESS') {
          alert("撤销续费成功" + result.data)
        } else {
          alert("撤销续费失败" + result.message)
        }
      }).catch(error => {
        alert("撤销续费失败 exception: " + error)
      })
    },
    async rejectRefill(index) {
      const rejectParam = {
        carId: this.onCarInfoList[index].id
      }
      http.post("/goods/rejectRefill", rejectParam).then(successResponse => {
        const result = successResponse.data;
        if (result.status === 'SUCCESS') {
          alert("拒绝续费成功" + result.data)
        } else {
          alert("拒绝续费失败" + result.message)
        }
      }).catch(error => {
        alert("拒绝续费失败 exception: " + error)
      })
    },
    async offCar(index) {
      const offParam = {
        id: this.onCarInfoList[index].id,
      }
      http.post("/goods/getOffCar", offParam).then(successResponse => {
        const result = successResponse.data;
        if (result.status == 'SUCCESS') {
          alert("下车成功" + result.data)
        } else {
          alert("下车失败" + result.message)
        }
      }).catch(error => {
        alert("下车失败 error: " + error)
      })
    },
    async getBill(index) {
      window.open("https://user.xinsi.us/?id=" + this.onCarInfoList[index].uuid);
    },
    async getRecentRefillListPage(p) {
      const param = {
        page: p,
        pageSize: 10
      }
      http.post("/goods/getRecentRefillListPage", param).then(successResponse => {
        const result = successResponse.data;
        if (result.status == 'SUCCESS') {
          const result = successResponse.data.data;
          this.onCarInfoList = result.list;
          this.onCarList = [];
          const list = this.onCarInfoList;

          for (let i =0; i < list.length; i++) {
            const item = {
              //id : {content: list[i].id, show: true},
              recentPaymentTime : {content: list[i].recentPaymentTime, show: true },
              goodsIdType :  {content: list[i].goodsIdType, show: true},
              accoutId :  {content: list[i].accoutId, show: true},
              payAmount :  {content: list[i].payAmount, show: true},
              contact :  {content: list[i].contact, show: true},
              debitType :  {content: list[i].debitType, show: true},
              nextPayTime : {content: list[i].nextPayTime, show: true}
            }

            this.onCarList.push(item);
          }

          this.total1 = result.total;
          this.pageSize = result.pageSize;
          this.currentPage1 = result.pageNum;
        } else {
          alert("查询失败" + result.message)
        }
      }).catch(error => {
        alert("查询失败 error: " + error)
      })
    },
    async getOnCarListPage(p) {
      const param = {
        searchTxt: this.searchTxt,
        accountId: this.accountId,
        page: p,
        pageSize: 10
      }
      http.post("/goods/getOnCarListPage", param).then(successResponse => {
        const result = successResponse.data.data;
        this.onCarInfoList = result.list;
        this.onCarList = [];
        const list = this.onCarInfoList;

        for (let i =0; i < list.length; i++) {
          const item = {
            //id : {content: list[i].id, show: true},
            recentPaymentTime : {content: list[i].recentPaymentTime, show: true },
            goodsIdType :  {content: list[i].goodsIdType, show: true},
            accoutId :  {content: list[i].accoutId, show: true},
            payAmount :  {content: list[i].payAmount, show: true},
            contact :  {content: list[i].contact, show: true},
            debitType :  {content: list[i].debitType, show: true},
            nextPayTime : {content: list[i].nextPayTime, show: true}
          }

          this.onCarList.push(item);
        }

        this.total1 = result.total;
        this.pageSize = result.pageSize;
        this.currentPage1 = result.pageNum;
      }).catch(error => {
        alert("getOnCarList: " + error)
      })
    },
    handleSizeChange1: function(pageSize) { // 每页条数切换
      this.pageSize = pageSize
      this.handleCurrentChange1(this.currentPage1);
    },
    handleCurrentChange1: function(currentPage) {//页码切换
      this.currentPage1 = currentPage;
      this.getOnCarListPage(currentPage);
      this.currentChangePage(this.bondsAllList,currentPage);

    },
    //分页方法（重点）
    currentChangePage(list,currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.tempList = [];
      for (; from < to; from++) {
        /*if (list[from]) {
          this.tempList.push(list[from]);
        }*/
      }

    },
    getItemDebitTypeSelect(e) {
      let itemDebitTypeSelectId = null;
      let itemDebitType = null;
      let index = null;
      [itemDebitTypeSelectId, itemDebitType, index] = e.split('|');
      this.onCarInfoList.at(index).debitTypeId =  itemDebitTypeSelectId;

      console.log("select DebitType id: " + this.debitTypeSelect.id + ' type: ' + itemDebitType)
    },
    tableRowClassName({ row, rowIndex }) {
      console.log("***************" + row.nextPayTime.content + ", rowIndex: " + rowIndex + ", " + this.dateDiffer(row.nextPayTime.content));
      //row.nextPayTime = '#3be6f8';
/*       if (row.column.prop === 'nextPayTime' ){
         return 'color: #3be6f8';
       }*/

      if (this.dateDiffer(row.nextPayTime.content) <= 15) {
        return 'info-row';
      }
    },
    dateDiffer(Date_end){
      //date1结束时间
      let date1 = new Date(Date_end);
      //date2当前时间
      let date2 = new Date();
      date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
      date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
      return diff / (24 * 60 * 60 * 1000);  //计算当前时间与结束时间之间相差天数
    }
  }
}
</script>

<style>
.el-table .info-row {
  color: #af3535;
}
</style>