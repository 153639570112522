<script setup>

</script>

<template>
  <div class="greetings">
    <h1 class="green">用户详情</h1>
  </div>
  <span> search user </span>
  <div class="form-group">
    <el-input type="text" class="form-control"  v-model="searchTxt" placeholder="关键字查找" style="width: 200px" />
    <el-button  color="#0080D0" @click="getUserInfo">查找</el-button>
  </div>
  <span> </span>
  <el-table :data="userList" stripe style="width: 100%; margin-top: 10px" :header-cell-style="{ background: '#0080D0', color : '#ebf1f0' }"
            border fit highlight-current-row>
    <el-table-column v-for="column in columnList" :key="column.prop">
      <!-- 自定义表头 -->
      <template #header>
        <!-- 段落：show为true -->
        <p v-show="column.show" @dblclick="column.show = false">
          {{column.label}}
          <i class="el-icon-edit-outline" @click="column.show = false"></i>
        </p>
        <!-- 输入框：show为false -->
        <el-input
            size="mini"
            v-show="!column.show"
            v-model="column.label"
            @blur="column.show = true">
        </el-input>
      </template>

      <!-- 自定义表项/单元格内容 -->
      <template #default="scope">
        <!-- 双击文字或点击修改图标以更改"show"属性 -->
        <!-- scope.row为元数据，column.col为该列的'键' -->
        <p v-show="scope.row[column.prop].show" @dblclick="scope.row[column.prop].show = false">
          {{ (scope.row[column.prop].content === null || scope.row[column.prop].content === '' ) ? "  -" : scope.row[column.prop].content}}
          <i class="el-icon-edit-outline" @click="scope.row[column.prop].show = false"/>
        </p>
        <!-- 失去焦点时更改"show"属性，显示文本 -->
        <el-input
            type="textarea"
            :autosize="{minRows:2,maxRows:4}"
            v-show="!scope.row[column.prop].show"
            v-model="scope.row[column.prop].content"
            @blur="scope.row[column.prop].show=true"/>
      </template>

    </el-table-column>


    <el-table-column v-if="columnList.length > 0"   :label="'操作'" :width="180">

      <template #default="scope">
        <el-button color="#0080D0" @click="update(scope.$index, scope.row)">修改</el-button>
        <el-button color="#0080D0" @click="del(scope.$index, scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <span> </span>

</template>

<script>

import http from "@/http-common";
export default {

  data() {
    return {
      userInfoList : [
        {
          id: null,
          tgId: null,
          tgName: null,
          wechatId: null,
          wechatName: null,
          saltyFishName: null,
          phoneNumber: null,
          ihezu: null,
          contact: null,
          goodsCount: null,
        },
      ],
      createTime: null,
      searchTxt: null,
      userList : [
      ],
      columnList: [
        { prop: "id", label: 'ID', show: true },
        { prop: "tgId", label: 'TgId', show: true },
        { prop: "tgName", label: 'TgName', show: true },
        { prop: "wechatId", label: 'WechatId', show: true },
        { prop: "wechatName", label: 'WechatName', show: true },
        { prop: "saltyFishName", label: 'SaltyFishName', show: true },
        { prop: "phoneNumber", label: 'phoneNumber', show: true },
        { prop: "taobao", label: 'Taobao', show: true },
        { prop: "email", label: 'Email', show: true },
        { prop: "ihezu", label: 'iHezu', show: true },
        { prop: "contact", label: 'Contact', show: true },
        { prop: "custom1", label: 'Spotify_id', show: true },
      ],
    }
  },
  mounted() {
    this.createTime = this.timeDefault;
    console.log("UserListView mounted()")
  },
  methods: {
    async getUserInfo() {
      const postData = {
        searchTxt: this.searchTxt,
      };
      http.post("/goods/getUserInfo", postData, {
        //
      }).then(successResponse => {
        this.userInfoList = successResponse.data.data;
        this.userList = [];
        for (let i = 0; i < this.userInfoList.length; i++) {
         const userItem = {
           id : { content: this.userInfoList[i].id, show : true},
           tgId: { content: this.userInfoList[i].tgId, show : true},
           tgName: { content: this.userInfoList[i].tgName, show : true},
           wechatId: { content: this.userInfoList[i].wechatId, show : true},
           wechatName: { content: this.userInfoList[i].wechatName, show : true},
           saltyFishName: { content: this.userInfoList[i].saltyFishName, show : true},
           phoneNumber: { content: this.userInfoList[i].phoneNumber, show : true},
           taobao: { content: this.userInfoList[i].taobao, show : true},
           email: { content: this.userInfoList[i].email, show : true},
           ihezu: { content: this.userInfoList[i].ihezu, show : true},
           contact: { content: this.userInfoList[i].contact, show : true},
           custom1: { content: this.userInfoList[i].custom1, show : true},
         };

          this.userList.push(userItem)
        }

      }).catch(failedResponse => {
        console.log(failedResponse.error)
      })
    },
    tableDbEdit(row, column, cell, event) {
      console.log("row: ", row, column, cell, event);
    },
    update(index, row) {

      const userItem = {
        id : null,
        tgId: null,
        tgName: null,
        wechatId: null,
        wechatName: null,
        saltyFishName: null,
        phoneNumber: null,
        taobao: null,
        email: null,
        ihezu: null,
        contact: null,
      };

      userItem.id = row.id.content;
      userItem.tgId = row.tgId.content;
      userItem.tgName = row.tgName.content;
      userItem.wechatId = row.wechatId.content;
      userItem.wechatName = row.wechatName.content;
      userItem.saltyFishName = row.saltyFishName.content;
      userItem.phoneNumber = row.phoneNumber.content;
      userItem.taobao = row.taobao.content;
      userItem.email = row.email.content;
      userItem.ihezu = row.ihezu.content;
      userItem.contact = row.contact.content;

      http.post("goods/updateUserInfo", userItem,{

      }).then(successResult => {
        console.log(successResult.data)
        alert("修改成功: " + successResult.data.data);
        //this.getUserInfo();
      }).catch(error => {
        console.log(error)
        alert("修改失败!");
      })
      console.log("update: ",index, "row: ", row.contact.content)
    },
    save() {

    },
    del(index, row) {
      const count = this.userInfoList[index].goodsCount;
      if (count <= 0) {
        this.$confirm('此操作不可逆，请再次确认，是否继续?  ', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //点击确定的操作(调用接口)
          const param = {
            id : row.id.content,
          }
          http.post("goods/deleteUser", param, {

          }).then(successResult => {
            alert("删除成功: " + successResult.data.data) ;
            this.getUserInfo();
          }).catch(error => {
            alert("删除失败: " + error) ;
          })

        }).catch(() => {
          //几点取消的提示
        });
      } else {
        this.$confirm('该用户名下商品总额为: ' + this.userInfoList[index].goodsCount +', 无法删除  ', '提示', {
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //点击确定的操作(调用接口)
          alert("无法删除")
        }).catch(() => {
          //几点取消的提示
        });
      }

    },
  }
}

</script>