import axios from 'axios'
import router from "@/router";

axios.defaults.timeout = 8000;
axios.defaults.baseURL = 'https://api.xinsichen.com/api';//http://localhost:8010/api

//设置请求头加入token
axios.interceptors.request.use(
    config => {
    console.log('请求: ' + config)
    if (config.push === '/') {
        //
    } else {
        if (localStorage.token) {
            console.log('setAuthorization')
            config.headers.Authorization = localStorage.token;
        }
        return config;
    }
}, error => {
    console.log("request reject");
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    console.log('响应: ' + response.data.status)

    if (response.data.status == 'UNAUTHORIZED') {
        console.log('token 已经过期');
        localStorage.removeItem('token');
        router.push({name: 'login'});
    } else {
        return response;
    }
},error => {
    return Promise.reject(error);
});

export default  axios;