<script>
import http from "../http-common"
import { reactive, toRefs } from "vue";
import { ref } from 'vue';
import dayjs from 'dayjs';

const currencyUnit = reactive({
  unitList: ["TL", "CNY", "USD", "EUR", "PHP"],
  unit: {
    name: "",
  },
});
const  { unitList, unit } = toRefs(currencyUnit);

const buyAmount = reactive({
  amountList: [1, 2, 3, 4, 5, 6, 7, 8],
  amount: {
    num: 0,
  },
});


const createTime = ref('')


const {amountList, amount } = toRefs(buyAmount);

export default {
  data() {
    console.log('data()', unitList, unit, amountList, amount, createTime)

    return {
      debitTypes: null,
      goodsTypes: null,
      debitTypeSelect : {
        id : 0,
        type : "",
      },
      goodsTypeSelect : {
        id : 0,
        type : "",
      },
      unitList : ["TL", "CNY", "USD", "EUR", "PHP"],
      unit : {
        name: "",
      },
      amountList : [1, 2, 3, 4, 5, 6, 7, 8],
      amount: {
        num: 0,
      },
      createTime: null,
    }
  },
  mounted() {
    this.createTime = this.timeDefault;
    console.log("GoodsAddView mounted()")
  },
  created() {
    this.getAllDebitTypes();
    this.getAllGoodsTypes();

    console.log("GoodsAddView created()")
    //this.test();
  },
  computed: {
    // 默认时间
    timeDefault () {
      console.log("GoodsAddView computed()")
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
          new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes();
      let ss =
          new Date().getSeconds() < 10
              ? "0" + new Date().getSeconds()
              : new Date().getSeconds();

      return yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
    }
  },
  methods: {
    getDebitTypeSelect(e) {
      [this.debitTypeSelect.id, this.debitTypeSelect.type] = e.split('|');
      //let [id, type] = e;
      console.log("select DebitType id: " + this.debitTypeSelect.id + ' type: ' + this.debitTypeSelect.type)
    },
    getGoodsTypeSelect(e) {
      //let [id , type] = e;
      [this.goodsTypeSelect.id, this.goodsTypeSelect.type] = e.split('|');
      console.log("select GoodsType id: " + this.goodsTypeSelect.id + ' type: ' + this.goodsTypeSelect.type)
    },
   async getAllDebitTypes() {
      http.get("/goods/getAllDebitTypes", {
        headers: {
          "x-access-token": "token-value",
        },
      }).then(successResponse => {
        this.debitTypes = successResponse.data.data;
        console.log("debitTypes: " , this.debitTypes);
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },
    async getAllGoodsTypes() {
      http.get("/goods/getAllGoodsTypes", {
        headers: {
          "x-access-token": "token-value",
        },
      }).then(successResponse => {
         this.goodsTypes = successResponse.data.data;
        console.log("goodsTypes: " , this.goodsTypes);
      }).catch(failResponse => {
        console.log(failResponse)
      });
    },
    async addGoodsInfo() {
      const postData = {
        debitTypeId: this.debitTypeSelect.id,
        goodsTypeId: this.goodsTypeSelect.id,
        accoutId: this.$refs.post_account.value,
        goodsPassword: this.$refs.post_password.value,
        amount: this.amount.num,
        priceUnit: this.unit.name,
        price: this.$refs.post_price.value,
        payment: this.$refs.post_payment.value,
        inviteLink: this.$refs.post_invite_link.value,
        createTime: this.createTime,
      };
      console.log("postData: " + JSON.stringify(postData))


      if (postData.debitTypeId === undefined || postData.debitTypeId === 0 ) {
        alert("debitType 不能为空 ")
      } else if (postData.goodsTypeId === undefined || postData.goodsTypeId === 0 ) {
        alert("goodsType 不能为空")
      } else if (postData.price === undefined || postData.price === null  || postData.price === '') {
        alert("price 不能为空")
      } else if (postData.priceUnit === undefined || postData.priceUnit === null || postData.priceUnit === '') {
        alert("priceUnit 不能为空")
      } else if (postData.accoutId === undefined || postData.accoutId === null || postData.accoutId === '' ) {
        alert("accoutId 不能为空")
      } else if (postData.amount === undefined || postData.amount === null) {
        alert("amount 不能为空")
      } else if (postData.payment === undefined || postData.amount === null) {
        alert("payment 不能为空")
      }
      else {
        postData.createTime = dayjs(postData.createTime).format("YYYY-MM-DD HH:mm:ss")
        console.log("postData.createTime: ", postData.createTime)
        http.post("/goods/addGoodsInfo", postData, {
          headers: {
            "x-access-token": "token-value",
          },
        }).then(successResponse => {
          const result = successResponse.data;
          console.log("success: " , result);
          if (result.status === "success") {
            alert("添加成功" + result.data)
          } else {
            alert(result.message)
          }

        }).catch(failResponse => {
          console.log(failResponse)
          alert("添加失败" + failResponse)
        });

      }
    },

  },

}
</script>

<template>
  <div class="greetings" >
    <h1 class="green">Goods</h1>

    <span>debit:</span>
    <el-select v-model="debitTypeSelect.type" placeholder="请选择" @change="getDebitTypeSelect">
      <el-option v-for="item in debitTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type}`">
      </el-option>
    </el-select>

    <span>goods:</span>
    <el-select v-model="goodsTypeSelect.type" placeholder="请选择" @change="getGoodsTypeSelect">
      <el-option v-for="item in goodsTypes" :key="item.id" :label="item.type" :value="`${item.id} | ${item.type}`">
      </el-option>
    </el-select>

    <div class="form-group">
      <input type="text" class="form-control" ref="post_account" placeholder="Account" /> <input type="text" class="form-control" ref="post_password" placeholder="Password" />
    </div>
    <div class="form-group">
      <input type="text" class="form-control" ref="post_invite_link" placeholder="inviteLink" /> <input type="text" class="form-control" ref="post_payment" placeholder="payment" />
    </div>
    <div class="form-group">
      <input type="text" class="form-control" ref="post_price" placeholder="price" />
      <el-select v-model="unit.name" placeholder="请选择">
        <el-option v-for="item in unitList" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>

    <div class="form-group">
      <el-select v-model="amount.num" placeholder="请选择">
        <el-option v-for="item in amountList" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="block">
      createTime
<!--      <span class="demonstration">Emits Date object</span>
      <div class="demonstration">Value: {{ value1 }}</div>-->
      <el-date-picker
          v-model="createTime"
          type="datetime"
          placeholder="Pick a Date"
          format="YYYY-MM-DD HH:mm:ss"
      />
    </div>
    <div class="add">
      <button class="btn btn-sm btn-primary" @click="addGoodsInfo">Add Goods</button>
    </div>
  </div>
</template>